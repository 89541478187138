<template>
  <div id="app">
    <canvas id="canvas"></canvas>
    <div id="season" v-if="showSeason">
        <p id="spring" class="seasonText">春</p>
        <p id="summer" class="seasonText">夏</p>
        <p id="autumn" class="seasonText">秋</p>
        <p id="winter" class="seasonText">冬</p>
    </div>
    <div id="enter" v-if="showEnter" @click="enterEarth">前进</div>
    
    <!-- 第一屏地球旋转，地图平移，字体展示 -->
    <section id="section-one">
      <countryMap v-if="showCountryMap" :earthTextureURL="earthTextureURL"></countryMap>
    </section>
    <!-- 第二屏地球移动到中间同时地球放大 -->
    <section id="section-two"></section>
    <!-- 第三屏三排字幕和地球自转 -->
    <section id="section-three">
      <earth-text v-if="showEarthText"></earth-text>
    </section>
    <!-- 第四屏地球缩小移动旋转，太阳放大移动。同时镜头变化 -->
    <section id="section-four">

    </section>
    <!-- 第五屏地球周转 -->
    <section id="section-five">
    </section>
    <section id="section-six">

    </section>
    <section id="section-seven">
      
    </section>
  </div>
</template>

<script>

import earthTexture from "./assets/2k_earth_daymap.webp"
import sunTexture from "./assets/2k_sun.webp"
import galaxyTexture from './assets/2k_stars_milky_way.webp'
let gsap = ''
let ScrollTrigger = ''
import * as THREE from 'three'


const devicePixelRatio = window.devicePixelRatio
// //引入orbitControls控制相机
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// //引入gui控制物体或者光源
import {GUI} from 'three/examples/jsm/libs/lil-gui.module.min.js'



export default{
  components:{
    countryMap:()=>import(/*webpackChunkName:"countryMap"*/'./components/countryMap.vue'),
    earthText:()=>import(/*webpackChunkName:"earthText"*/'./components/earthText.vue')
  },
  data(){
    return{
      earthTextureURL:earthTexture,
      showEarthText:false, //动态展示组件
      showCountryMap:false,
      movingAnimate:false,
      earthTexture:'',
      earth:'', //地球threejs模型
      renderer:'', 
      scene:'',
      width:'', //屏幕的宽
      height:'', //屏幕的高
      camera:'',
      galaxy:'', //银河模型
      galaxyPlane:'', //银河平面
      galaxyPlaneMaterial:'', //银河平面的材质
      galaxyPlaneTexture:'',
      circlePlane:'', //地球周转运动轨迹
      earthRotateAnimate:false,  //地球自转动画
      earthRevolutionAnimate:false, //地球周转动画
      resolutionAngle:0, //地球周转角度
      lightAnimate:false, //光照变化
      rotation:0, //地球自转每帧旋转的角度
      distance:'', //地球公转的距离
      revolutionY:0, //地球绕着y轴旋转的角度
      sun:'', //判断有无太阳
      circleMaterial:'', //圆平面材质
      showSeason:false,
      showEnter:false,
      galaxyMaterial:'',
      galaxySpeed:0, //银河运动速度
      galaxyTime:0, //银河运动时间
      galaxyAnimate:false, //银河运动动画
      changeRouter:false, //切换路由
    }
  },
  methods:{
    /*
      动态加载图片
    */
  //  loadImage(){
  //    const imgURL = "./img/2k_earth_daymap.webp"
  //    const img = new Image()
  //    img.src = imgURL
  //    img.onload=()=>{
  //       earthTexture = img
  //    }
  //  },
    /*
      创建物体，场景或者矩阵
    */
    createGalaxyPlane(){
      if(this.galaxyPlane){
        this.scene.add(this.galaxyPlane)
        return
      } 
      const planeGeometry = new THREE.PlaneGeometry(this.width, this.height)
      const material = new THREE.MeshBasicMaterial({ 
        map: this.galaxyPlaneTexture,
        transparent: true,
      })
      const galaxyPlane = new THREE.Mesh(planeGeometry, material)
      galaxyPlane.position.set(0,0,-2000)
      this.galaxyPlane = galaxyPlane
      this.galaxyPlaneMaterial = material
      this.scene.add(galaxyPlane)
    }, //创建一个平面有银河的贴图并添加进场景
    createGalaxy(){
      const starCount = 5000 // 粒子数量
      const geometry = new THREE.BufferGeometry()
      const position = new Float32Array(starCount * 3)

      for (let i = 0; i < starCount; i++) {
        let j = i*3
        position[j] = Math.random()*this.width*2-this.width,
        position[j+1] = Math.random()*this.height*2-this.height,
        position[j+2] = - Math.random()*10000
      }
      geometry.setAttribute('position', new THREE.BufferAttribute(position, 3))
      const particleMaterial = new THREE.ShaderMaterial({
        vertexShader:`
          uniform float uSpeed;
          uniform float uTime;
          void main(){
            vec4 viewPosition = modelViewMatrix * vec4(position,1.0);
            viewPosition.z += uTime * uSpeed;
            gl_PointSize = 5.0 + (100.0/-viewPosition.z);
            gl_Position = projectionMatrix  * viewPosition;
          }
        `,
        fragmentShader:`
          precision mediump float;
          void main(){
            vec2 center = vec2(0.5, 0.5); 
            vec2 position = gl_PointCoord - center;
            float distance = length(position);
            if (distance > 0.2) {
              discard;
            }
            gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0); 
        }
        `,
        uniforms:{
          uSpeed:{value:this.galaxySpeed},
          uTime:{value:this.galaxyTime}
        },
        transparent: true,
      })
      this.galaxyMaterial = particleMaterial
      const particleSystem = new THREE.Points(geometry, particleMaterial)
      this.galaxy =  particleSystem
      this.scene.add(particleSystem)
    }, //使用point模型创建银河
    createCirclePlane(x,y,startAngle){// 创建矩形的路径
      if(this.circlePlane){
        this.scene.add(this.circlePlane)
        return
      }
      const planePath = new THREE.Shape()
      planePath.moveTo(-x/2, -y/2)
      planePath.lineTo(x/2, -y/2)
      planePath.lineTo(x/2, y/2)
      planePath.lineTo(-x/2, y/2)
      // 创建圆弧的路径    
      const radius = y/2
      const circleStartAngle = startAngle
      const circleEndAngle = Math.PI*2
      const circlePath = new THREE.Path()
      circlePath.absarc(0, 0, radius, circleStartAngle, circleEndAngle, false)
      circlePath.lineTo(0,0)
      // 从矩形路径中排除圆弧路径
      planePath.holes.push(circlePath)

      // 创建几何体
      const extrudeSettings = {
        depth: 0.1,
        bevelEnabled: false,
        curveSegments:32
      }
      const urColor =new THREE.Vector3(57/255,246/255,201/255)
      const ulColor =new THREE.Vector3(40/255,205/255,233/255)
      const dlColor =new THREE.Vector3(240/255,194/255,55/255)
      const drColor =new THREE.Vector3(223/255,229/255,239/255)

      const geometry = new THREE.ExtrudeGeometry(planePath, extrudeSettings)
      const circleMaterial = new THREE.ShaderMaterial({ vertexShader:`
        void main(){
          gl_Position = projectionMatrix  * modelViewMatrix * vec4(position,1.0);
        }`,fragmentShader:`
        precision mediump float;
        uniform vec2 resolution;
        uniform vec3 urColor;
        uniform vec3 ulColor;
        uniform vec3 dlColor;
        uniform vec3 drColor;
        uniform float angle;
        uniform float ratio;
        void main(){
          vec2 st = gl_FragCoord.xy / resolution.xy;
          st -= vec2(0.5,0.5);
          float circleAngle = atan(ratio*st.y/st.x);
          if(st.x>0.0&&st.y>0.0){
            float result = step(angle,circleAngle);
            vec3 color = (1.0-result)*urColor+result*vec3(1.0,1.0,1.0);
            gl_FragColor = vec4(color,0.8);
          }else if(st.x<0.0&&st.y>0.0){
            float result = step(angle,circleAngle+3.141592653589793);
            vec3 color = (1.0-result)*ulColor+result*vec3(1.0,1.0,1.0);
            gl_FragColor = vec4(color,0.8);
          }else if(st.x<0.0&&st.y<0.0){
            float result = step(angle,circleAngle+3.141592653589793);
            vec3 color = (1.0-result)*dlColor+result*vec3(1.0,1.0,1.0);
            gl_FragColor = vec4(color,0.8);
          }else{
            float result = step(angle,circleAngle+6.2831853067179586);
            vec3 color = (1.0-result)*drColor+result*vec3(1.0,1.0,1.0);
            gl_FragColor = vec4(color,0.8);
          }
        }`,
        uniforms:{
          resolution: { value: new THREE.Vector2(this.width*devicePixelRatio,this.height*devicePixelRatio) },
          urColor:{value:urColor},
          ulColor:{value:ulColor},
          dlColor:{value:dlColor},
          drColor:{value:drColor},
          angle:{value:this.resolutionAngle},
          ratio:{value:this.height/this.width}
        },
        transparent: true
      })
      const circlePlane = new THREE.Mesh(geometry, circleMaterial)
      this.circlePlane = circlePlane
      this.circleMaterial = circleMaterial
      this.scene.add(circlePlane)
    }, //创建一个中间缺失圆形的矩形，用于表示四季
    createSun(){
      if(this.sun){
        this.scene.add(this.sun)
        return
      }
      const width = this.width
      const height = this.height
      const radius = height/10
      const textureloader = new THREE.TextureLoader()
      const texture = textureloader.load(sunTexture)
      const geometry = new THREE.SphereGeometry( radius, 128, 64 )
      const material = new THREE.ShaderMaterial({ vertexShader:`
        varying vec2 vUv;
        void main(){
          gl_Position = projectionMatrix  * modelViewMatrix * vec4(position,1.0);
          vUv = uv;
        }`,fragmentShader:`
        precision mediump float;
        uniform sampler2D sunTexture;
        varying vec2 vUv;
        void main(){
          gl_FragColor = texture2D(sunTexture,vUv);
        }`,
        uniforms:{
          sunTexture:{value:texture}
        }
      })
      const sun = new THREE.Mesh(geometry,material) 
      sun.position.set(-(width/2+radius),0,0)
      this.scene.add(sun)
      this.sun = sun
    }, //创建太阳
    createMatrix(){
      const matrix =new THREE.Matrix3()
      matrix.set(
        1,0,0,
        0,1,0,
        0,0,1,
      )
      return matrix
    }, //返回一个单位矩阵
    createEarthRotationMatrix(rotation,axis){
      rotation = rotation/2
      const a = Math.cos(rotation)
      const b = Math.sin(rotation)*axis[0]
      const c = Math.sin(rotation)*axis[1]
      const d = Math.sin(rotation)*axis[2]
      const matrix = new THREE.Matrix3()

      matrix.set(
        1-2*c*c, 2*b*c, 2*a*c,
        2*b*c, 1-2*b*b, -2*a*b,
        -2*a*c, 2*a*b, 1-2*b*b-2*c*c,
      )
      return matrix
    },//创建地球自转矩阵

    /*
      移除物体
    */
    removeGalaxy(){
      if(this.galaxy){
        this.scene.remove(this.galaxy)
      }
    },
    removeSun(){
      this.scene.remove(this.sun)
    }, //移除太阳
    removeGalaxyPlane(){
      this.scene.remove(this.galaxyPlane)
    },
    removeCirclePlane(){
      if(this.circlePlane){
        this.scene.remove(this.circlePlane)
      }
    },


    initThreeJs(){
      
      const canvas = document.getElementById('canvas')
      //获取canvas显示的宽高
      const width = canvas.clientWidth 
      const height = canvas.clientHeight
      this.width = width
      this.height = height
      this.distance = width/4
      const earthRadius = height/5
      const scene = new THREE.Scene()
      this.scene = scene
      scene.background = null
      // const axesHelper = new THREE.AxesHelper(1500)//参数为坐标轴的长度
      // scene.add(axesHelper)
      let rotationMatrix = this.createMatrix()
      //设置平行光的方向
      const direactionLightDirection =  new THREE.Vector3(0.0,0.0,1.0)
      const geometry = new THREE.SphereGeometry( earthRadius, 128, 64 ) //创建一个球体
      const material = new THREE.ShaderMaterial({ vertexShader:`
        uniform mat3 rotationMatrix;
        uniform float angleY;
        uniform float distance;
        varying vec2 vUv;
        varying vec3 vNormal;
        void main(){
          mat3 rotateY = mat3(
            cos(angleY),0.0,sin(angleY),
            0.0,1.0,0.0,
            -sin(angleY),0.0,cos(angleY)
          );
          vec3 lightNormal = normalize(normalMatrix * normal); 
          vNormal = rotationMatrix * lightNormal;
          vec3 rotationPosition = rotationMatrix * position;
          vec3 movingBeforePosition = rotationPosition + vec3(distance,0,0);
          vec3 revolitionPosition =  rotateY * movingBeforePosition;
          vec3 movingAfterPosition = revolitionPosition + vec3(-distance,0,0);
          gl_Position = projectionMatrix  * modelViewMatrix  * vec4(movingAfterPosition,1.0);
          vUv = uv;
        }`,fragmentShader:`
        precision mediump float;
        uniform sampler2D earthTexture;
        varying vec2 vUv;
        varying vec3 vNormal;
        uniform vec3 direactionLightDirection;
        void main(){ 
          float distance = length(gl_PointCoord);
          float atmosphereIntensity =smoothstep(0.99,1.0,64.0*pow(distance, 3.0));
          float intensity = 1.05 - dot(vNormal,vec3(0.0,0.0,1.0));
          vec3 atmosphere = vec3(0.3,0.6,1.0) * pow(intensity,1.0) * atmosphereIntensity;
          vec3 lightColor = vec3(1.0,1.0,1.0);
          vec3 earthColor = texture2D(earthTexture,vUv).rgb;
          float lightIntensity = smoothstep(0.0,1.0,dot(vNormal, direactionLightDirection)); 
          gl_FragColor = vec4(atmosphere+(lightColor*lightIntensity)*earthColor,1.0);

        }`,
        uniforms:{
          earthTexture:{value:this.earthTexture},
          rotationMatrix:{value:rotationMatrix},
          direactionLightDirection:{value:direactionLightDirection},
          angleY:{value:this.revolutionY},
          distance:{value:this.distance}
        },
      }) 

      const earth = new THREE.Mesh(geometry,material) 
      earth.position.set(width/2-earthRadius,0,0)
      scene.add(earth)
      this.earth = earth
      //3.设置相机。正交投影相机(OrthographicCamera)
      const camera = new THREE.OrthographicCamera(width / - 2, width / 2, height / 2, height / - 2, 1, 10000 )
      camera.position.set(0,0,5000) //设置视点
      camera.lookAt(0,0,0) //设置观察点
      this.camera = camera
      //5.渲染器设置。
      const renderer = new THREE.WebGLRenderer({
        canvas:canvas,
        alpha: true, //变透明
        antialias:true, //抗锯齿
      })
      this.renderer = renderer
      renderer.setSize(width,height) //设置渲染的宽高，与canvas显示的宽高一致。
      renderer.setPixelRatio(devicePixelRatio)
      renderer.render(scene,camera) //将场景和相机放入渲染器
      //6.鼠标拖动改变相机方向
      // const controls = new OrbitControls(camera, renderer.domElement)
      // controls.addEventListener('change', function () {
      //     renderer.render(scene, camera) //执行渲染操作
      // })
      const self = this
      function animate() {
          if(self.earthRotateAnimate) {
            self.rotation -= Math.PI/90
            material.uniforms.rotationMatrix.value = self.createEarthRotationMatrix(self.rotation,[-Math.sin((23.5*Math.PI)/180),Math.cos((23.5*Math.PI)/180),0])
          } else {
            material.uniforms.rotationMatrix.value = self.createMatrix()
          } //地球自转
          if(self.lightAnimate){
            material.uniforms.direactionLightDirection.value = new THREE.Vector3(-1.0,0.0,0.0)
          }else{
            material.uniforms.direactionLightDirection.value = new THREE.Vector3(0.0,0.0,1.0)
          } //平行光源的方向
          if(self.galaxyAnimate){
            if(self.galaxyMaterial.uniforms.uTime.value<360){
                let time = self.galaxyMaterial.uniforms.uTime.value
                self.galaxyMaterial.uniforms.uTime.value +=1
                self.galaxyMaterial.uniforms.uSpeed.value = 100
                self.galaxyPlane.material.opacity = 1-time/360
            }else{
                if(!self.changeRouter){
                  self.$router.push('/watchEarth')
                  self.changeRouter = true
                }
            } //动画完成切换路由
          }
          material.uniforms.angleY.value = self.revolutionY
          if(self.circleMaterial) self.circleMaterial.uniforms.angle.value = self.resolutionAngle
          renderer.render(scene, camera)
          requestAnimationFrame(animate)
      }
      animate()
    }, //初始化
    //在地图平移的过程中，地球旋转

    /*
      gasp动画
    */
    gsapAnimate(){
      this.earthRotate()
      this.earthMove()
      this.earthRotation()
      this.revolution()
      this.beforeGalaxyAnimation()
    },
    earthRotate(){
      const t1 = gsap.timeline({
        scrollTrigger:{
          trigger:'#canvas',
          start:'top top',
          end:'+=500',
          scrub:true,
        },
      })
      t1.to(this.earth.rotation,{
         y:Math.PI*2,
      }).to('#canvas',{
        zIndex:0
      })
    },
    //地球平移并变大
    earthMove(){
      const t1 = gsap.timeline({
        scrollTrigger:{
          trigger:'#section-two',
          start:'top top',
          end:'bottom top',
          scrub:true,
          onEnter:()=>{
            this.showEarthText = true
            this.loadGalaxyPlane()
            this.createGalaxy()
          },
          onLeaveBack:()=>{
            this.removeGalaxy()
          }
        },
      })
      t1.to(this.earth.position,{
        x:0,
      }).to(this.earth.scale,{
        x:2.25,
        y:2.25,
        z:2.25
      },'<')
    },
    //地球倾斜并旋转
    earthRotation(){
      const angle = 23.5*Math.PI/180 //旋转角
      const t1 = gsap.timeline({
        scrollTrigger:{
          trigger:'#section-three',
          start:'top top',
          end:'33% top',
          scrub:true,
          markers:false,
          onEnter:()=>{
            this.createGalaxyPlane()
            this.earthRotateAnimate = false
            this.createSun()
            this.moving()
          },
          onLeaveBack:()=>{
            this.removeGalaxyPlane()
          },
        }
      })
      gsap.timeline({
        scrollTrigger:{
          trigger:'#section-three',
          start:'34% top',
          end:'67% top',
          scrub:true,
          markers:false,
          onUpdate:(self)=>{
            if(self.progress<0.1){
              this.earthRotateAnimate = false
            }else{
              this.earthRotateAnimate = true
            }
          }
        }
      })
      const t3 = gsap.timeline({
        scrollTrigger:{
          trigger:'#section-three',
          start:'68% top',
          end:'bottom top',
          scrub:true,
          markers:false,
          onUpdate:(self)=>{
            if(self.progress<0.1){
              this.lightAnimate = false
            }else{
              this.lightAnimate = true
            }
          }
        }
      })
      t1.to(this.earth.rotation,{
        z:angle
      })

    },//地球倾斜23.5度同时绕着这个轴旋转，
    moving(){
      if(this.movingAnimate) return
      this.movingAnimate = true
      const t1 = gsap.timeline({
        scrollTrigger:{
          trigger:'#section-four',
          start:'500 top',
          end:'bottom top',
          scrub:true,
          markers:false,
          onUpdate:(self)=>{
            this.revolutionY = self.progress*Math.PI*2
          }
        },
      })
      t1.to(this.sun.position,{
        x:0
      }).to(this.sun.scale,{
        x:2,
        y:2,
        z:2
      },'<').to(this.earth.scale,{
        x:2/9,
        y:2/9,
        z:2/9
      },'<').to(this.earth.position,{
        x:this.width/4
      },'<')
    },
    revolution(){
      gsap.timeline({
        scrollTrigger:{
          trigger:'#section-five',
          start:'top top',
          end:'bottom top',
          scrub:true,
          markers:false,
          onUpdate:(self)=>{
            let angle = self.progress*Math.PI*2
            this.resolutionAngle = angle
            this.earth.position.x = this.distance*Math.cos(angle)
            this.earth.position.y = this.distance*Math.sin(angle)
          },
          onEnter:()=>{
            this.createCirclePlane(this.width,this.height,0)
            this.showSeason = true
          },
          onLeaveBack:()=>{
            this.showSeason = false
            this.removeCirclePlane()
          },
          onEnterBack:()=>{
            this.createSun()
            this.createCirclePlane()
            this.showSeason = true
            this.showEnter = false
          }
        },
      })
    },//地球周转
    /*
      提前加载纹理
    */
    loadGalaxyPlane(){
      if(this.galaxyPlaneTexture) return       
      const textureLoader = new THREE.TextureLoader()
      const texture = textureLoader.load(galaxyTexture)
      this.galaxyPlaneTexture = texture
    },
    loadEarth(){
      this.earthTexture =new THREE.TextureLoader().load(this.earthTextureURL)
      this.showCountryMap = true
    },
    beforeMoveToEarth(){
      function preventDefault(e){
        e.preventDefault()
      }
      const timeline = gsap.timeline({
        onStart:()=>{
          this.showSeason =false
          this.removeSun()
          this.removeCirclePlane()
          document.addEventListener('wheel', preventDefault, { passive: false })
        }, //动画开始时禁用用户鼠标
        onComplete:()=>{
          this.showEnter = true
        }
      })
      timeline.to(this.earth.position, { duration: 2, x: 0})
    },
    beforeGalaxyAnimation(){
      gsap.timeline({
        scrollTrigger:{
          trigger:'#section-six',
          start:'0% top',
          scrub:true,
          onEnter:()=>{
            this.beforeMoveToEarth()
          }
        },
      })
    },
    enterEarth(){
      this.galaxyAnimate = true
      this.showEnter = false
    },

    /*
      动态引入库
    */
   preloadImport(){
     import("gsap").then((res)=>{
       gsap = res.gsap
       return gsap
     }).then((gsap)=>{
      import("gsap/ScrollTrigger").then((res)=>{
        ScrollTrigger = res.ScrollTrigger
        gsap.registerPlugin(ScrollTrigger)
        this.gsapAnimate()
      })
     })
   }
  },
  created(){
    this.loadEarth()
  },
  mounted(){
    this.initThreeJs()
    this.preloadImport()    
  }
}
</script>

<style>

#enter{
  position: fixed;
  width: 380px;
  height: 86px;
  bottom: 0;
  left: 50%;
  transform: translateX(-190px);
  margin-bottom: 100px;
  font-size: 40px ;
  text-align: center;
  line-height: 88px;
  background: linear-gradient(45deg,transparent 5%,#FF013c 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
}

.seasonText{
  margin: 20px;
  font-size: 40px;
  position: absolute;
}
#spring{
  right: 0;
}
#summer{

}
#autumn{
  bottom: 0;
}
#winter{
  right: 0;
  bottom: 0;
}
#season{
  position: fixed;
  width: 100%;
  height: 100vh;
}
#canvas{
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: 1;
  width: 100%;
  height: 100vh;
}
.section{
  position: relative;
  width: 100%;
  height: 100vh;
}

#section-one{
  height: 200vh;
}
#section-two{
  height: 100vh;
}
#section-three{
  height: 300vh;
}
#section-four{
  height: 400vh;
}
#section-five{
  height: 400vh;
}
#section-six{
  height: 100vh;
}
#section-seven{
  height: 100vh;
}
#app{
  overflow: hidden;
}
</style>
