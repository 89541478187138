import Vue from 'vue'
import VueRouter from 'vue-router'
import earthAnimate from '../earthAnimate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'earthAnimate',
    component: earthAnimate
  },
  {
    path: '/watchEarth',
    name: 'watchEarth',
    component: function(){
      return import('../watchEarth.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
