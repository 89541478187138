<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
import earthAnimate from "./earthAnimate.vue"
import watchEarth from './watchEarth.vue'
export default{
    components:{
        earthAnimate,
        watchEarth
    },
}
</script>
<style scoped>


</style>