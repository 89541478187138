<template>
  <div id="app">
      <a id="author" href="https://seele-exzzz-github-io.pages.dev/" target="_blank">
        个人博客
      </a>
      <div id="earth"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts-gl'
import baseTexture from './assets/2k_earth_daymap.webp'
import environment from './assets/2k_stars_milky_way.webp'

export default{
  data(){
    return{
      charts:'', 
      
    }
  },
  methods:{
      init(){
        const earth = document.getElementById('earth')
        const earthCharts = echarts.init(earth)
        const country = document.createElement('canvas')
        const countryCharts = echarts.init(country,null,{
          width:2048,
          height:1024
        })
        import('./world.json').then((mapData)=>{
          echarts.registerMap('world',mapData)
          const countryOption={
            series:[{
                type: 'map',
                map: 'world',
                top:0,
                left:0,
                right:0,
                bottom:0,
                boundingCoords:[
                  [-180,90],
                  [180,-90]
                ],
                itemStyle:{
                  areaColor:'rgba(0,0,0,0)', //地图区域颜色改成透明
                  borderColor:'rgba(255,255,255,0.2)' //地图边框颜色改成透明
                },
                emphasis:{
                  itemStyle:{
                    areaColor:'rgba(0,0,0,0)',
                    borderColor:'rgba(255,255,255,0.7)'
                  }
                },
                select:{
                  itemStyle:{
                    areaColor:'rgba(0,0,0,0)',
                    borderColor:'rgba(255,255,255,1.0)'
                  }
                  
                }
            }],
          } //国家
          countryCharts.setOption(countryOption)
          const option = {
            globe:{
              globeRadius:5,
              baseTexture,
              heightTexture:baseTexture,
              shading:'realistic',
              atmosphere:{
                color:'#fff',
                glowPower:10,
                innerGlowPower:3
              },
              environment,
              realisticMaterial:{
                roughness:0.9
              },
              light:{
                ambient:{
                  intensity:0.8
                }, //环境光
                main:{
                  intensity:0.5,
                  shadow:true
                },
              },
              viewControl:{
                autoRotateSpeed:360,
              },
              layers:[
              {
                show:true,
                type:'blender',
                texture:countryCharts,
              },
              ]
            }
          } //地球
          earthCharts.setOption(option)
          function animate() {
            option.globe.globeRadius+=0.8
            earthCharts.setOption(option)
            let animateId = requestAnimationFrame(animate)
            if(option.globe.globeRadius>=65){
              option.globe.viewControl.autoRotate = false
              option.globe.atmosphere.show = true
              cancelAnimationFrame(animateId)
              earthCharts.setOption(option)
            }    
          }
          animate()
        })

      }//初始化
    },
  mounted(){
    this.init()
  }
}
</script>

<style scoped>
#author{
  position: absolute;
  z-index: 1;
  width: 150px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 20px;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  text-decoration: none;
  color: aqua;
}

#earth{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
